import './styles/Loader.css';

const Loader = () => {
	return ( 
		<div className="Loader">
			<span></span>
		</div>
	 );
}
 
export default Loader;