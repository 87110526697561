import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import Navigation from "../components/Navigation";
import './styles/ViewDuck.css';

const ViewDuck = ({Global}) => {
	const { username } = useParams();
	const [loading, setLoading] = useState(true);
	const [duck, setDuck] = useState(null);
	const [followPending, setFollowPending] = useState(false);
	const [error, setError] = useState(null);

	const init = async () => {
		var req = await fetch("https://api.gduck.io:6180/v1/ducks/get?username="+username.slice(1), {
			headers: {
				"Authorization": Global.token
			}
		});
		var res = await req.json();
		console.log(res);
		if(res.code!==0) {
			setError(res.message);
		}
		else {
			setDuck(res.data);
		}
		setLoading(false);
	}
	useEffect(() => {
		init();	
	}, []);

	const followDuck = async () => {
		setFollowPending(true);
		var req = await fetch("https://api.gduck.io:6180/v1/ducks/follow/"+duck.id, {
			method: "GET",
			headers: {
				"Authorization": Global.token,
			}
		});
		var res = await req.json();
		console.log(res);
		if(res.code!==0) {
			alert(res["message"]);
		}
		else {
			init();
		}
		setFollowPending(false);
	};

	if(loading) {
		return (
			<div className="mainView">
				<div className="col1"><Navigation Global={Global} /></div>
				<div className="col2"><Loader /></div>
			</div>
		 );
	}
	else if(error) {
		return (
			<div className="mainView">
				<div className="col1">
					<Navigation Global={Global} />
				</div>
				<div className="col2">
					<div className="ViewDuck">
						<h1>-</h1>
						<h2>@{username.slice(1)}</h2>
						<p>{error}</p>
					</div>
				</div>
			</div>
		 );
	}
	else {
		return (
			<div className="mainView">
				<div className="col1">
					<Navigation Global={Global} />
				</div>
				<div className="col2">
					<div className="ViewDuck">
						<h1>{duck.name}</h1>
						<h2>@{duck.username}</h2>
						<p>{duck.bio}</p>
						{!followPending && <button onClick={followDuck}>{duck.isFollowing ? "Following" : "Follow"}</button>}
						<code>{JSON.stringify(duck)}</code>
					</div>
				</div>
			</div>
		 );
	}
	
}
 
export default ViewDuck;