import { useEffect, useState } from "react";
import Loader from './Loader';
import './styles/Feed.css';
import { useNavigate, Link } from "react-router-dom";
import {FeedQuack} from "./Quacks";

const Feed = ({Global, type}) => {
	const [loading, setLoading] = useState(true);
	const [ducks, setDucks] = useState(null);
	const [quacks, setQuacks] = useState(null);

	const [quackText, setQuackText] = useState("");
	const [quackPending, setQuackPending] = useState(false);

	const init = async () => {
		var req = await fetch("https://api.gduck.io:6180/v1/quacks/"+type, {
			headers: {
				"Authorization": Global.token
			}
		});
		var res = await req.json();
		console.log(res);
		if(res.code===800) window.location.href = "/login";
		else if(res.code!==0) {
			return;
		}
		setDucks(res.data.ducks);
		setQuacks(res.data.quacks);
		setLoading(false);
	}
	useEffect(() => {
		init();	
	}, []);

	const postQuack = async () => {
		setQuackPending(true);
		var req = await fetch("https://api.gduck.io:6180/v1/quacks/quack", {
			method: "POST",
			headers: {
				"Authorization": Global.token,
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				text: quackText
			})
		});
		var res = await req.json();
		console.log(res);
		if(res.code!==0) {
			alert(res["message"]);
		}
		else {
			init();
		}
		setQuackPending(false);
	};

	if(loading) {
		<div className="Feed">
			<Loader />
		</div>
	}
	else {
		return ( 
			<div className="Feed">
				{quackPending && 
					<Loader />
				}
				{!quackPending && 
					<>
					<input type="text" placeholder="What's on your mind?" onChange={(event) => setQuackText(event.target.value)} />
					<button className="btn1" onClick={postQuack}>Post</button>
					</>
				}
				{quacks.map((quack) => {
					var duck = ducks[quack.duckID];
					return (
						<FeedQuack Global={Global} duck={duck} quack={quack} key={quack.id} />
					);
				})}
			</div>
		 );
	}
	
}

 
export default Feed;