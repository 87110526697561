import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/Navigation";
import Feed from "../components/Feed";
import './styles/Home.css';

const Home = ({Global, type}) => {
	const navigate = useNavigate();
	
	const init = async () => {
		if(!Global.token) navigate("/login");
	};
	useEffect(() => {
		init();
	}, []);

	

	return ( 
		<div className="Home mainView">
			<div className="col1">
				<Navigation Global={Global} />
			</div>
			<div className="col2">
				<Feed Global={Global} type={type} />
			</div>
			
		</div>
	);
}
 
export default Home;