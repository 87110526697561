import {Link, useNavigate} from 'react-router-dom';
import {useState} from 'react';
import './styles/Quacks.css';

const doLike = async (Global, quack, liked, setLiked, setLikeCount) => {
	// Pre-update the UI
	setLiked(!liked);

	var req = await fetch("https://api.gduck.io:6180/v1/quacks/like/"+quack.id, {
		method: "GET",
		headers: {
			"Authorization": Global.token
		}
	});
	var res = await req.json();
	if(res.code===0) {
		setLiked(res.data.liked);
		setLikeCount(res.data.likeCount);
	}
};

const FeedQuack = ({Global, duck, quack}) => {
	if(!duck) duck = {name: `Deleted User ${quack.duckID}`, username: quack.duckID};

	const navigate = useNavigate();
	const [liked, setLiked] = useState(quack.liked);
	const [likeCount, setLikeCount] = useState(quack.likeCount);
	var date = new Date(quack.timestamp);

	return ( 
		<div className="FeedQuack">
			<div className="quackContent" onClick={() => {
				navigate(`/quack/${quack.id}`);
				navigate(0);
			}}>
				<a href={`/duck/@${duck.username}`}>
					<h1>{duck.name}</h1>
					<br />
					<h2>@{duck.username}</h2>
					<br />
				</a>
				<p>{quack.text}</p>
				<footer>{date.toLocaleString("en-US")}</footer>
			</div>
			<div className="actionBar">
				<button onClick={(event) => doLike(Global, quack, liked, setLiked, setLikeCount)}>{liked ? "❤️" : "🤍"}</button>
				<h6>{likeCount}</h6>
			</div>
		</div>
	 );
}

const MainQuack = ({Global, duck, quack}) => {
	if(!duck) duck = {name: `Deleted User ${quack.duckID}`, username: quack.duckID};

	return ( 
		<div className="MainQuack">
			<a href={`/duck/@${duck.username}`}>
				<h1>{duck.name}</h1>
				<br />
				<h2>@{duck.username}</h2>
				<br />
			</a>
			<p>{quack.text}</p>
			<code>{JSON.stringify(quack)}</code>
		</div>
	 );
}

const ChildQuack = ({Global, ducks, quack}) => {
	// const duck = ducks[quack.duckID];
	// return (
	// 	<div className="ChildQuack">
	// 		<p>@{duck ? duck.username : quack.duckID}: {quack.text}</p>
	// 	</div>
	// );
};
 
export { FeedQuack, MainQuack, ChildQuack };