import { useNavigate } from 'react-router-dom';
import './styles/Navigation.css';

const Navigation = ({Global}) => {
	const navigate = useNavigate();
	const logout = async () => {
		Global.clearToken();
		navigate("/login");
	};

	return ( 
		<nav className="Navigation">
			<a href="/"><button>All Quacks</button></a>
			<a href="/following"><button>Following</button></a>
			<button>Search</button>
			<button onClick={logout}>Logout</button>
			<code>v0.1.15</code>
		</nav>
	 );
}
 
export default Navigation;