import { useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import Loader from '../components/Loader';
import Navigation from "../components/Navigation";
import './styles/ViewQuack.css';
import {MainQuack, FeedQuack} from '../components/Quacks';

const ViewQuack = ({Global}) => {
	const { qid } = useParams();
	const [loading, setLoading] = useState(true);
	const [commentPending, setCommentPending] = useState(false);
	const [quack, setQuack] = useState(null);
	const [duck, setDuck] = useState(null);
	const [children, setChildren] = useState(null);
	const [commentText, setCommentText] = useState("");

	const init = async () => {
		var req = await fetch("https://api.gduck.io:6180/v1/quacks/get/"+qid, {
			headers: {
				"Authorization": Global.token
			}
		});
		var res = await req.json();
		console.log(res);
		if(res.code!==0) {
			return;
		}
		setQuack(res.data.quack);
		setDuck(res.data.duck);
		setChildren(res.data.children);
		setLoading(false);
	}
	useEffect(() => {
		init();	
	}, []);

	const postComment = async () => {
		setCommentPending(true);
		var req = await fetch("https://api.gduck.io:6180/v1/quacks/quack", {
			method: "POST",
			headers: {
				"Authorization": Global.token,
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				text: commentText,
				parentID: quack.id
			})
		});
		var res = await req.json();
		console.log(res);
		if(res.code!==0) {
			alert(res["message"]);
		}
		else {
			init();
		}
		setCommentPending(false);
	};

	if(loading) {
		return (
			<div className="mainView">
				<div className="col1"><Navigation Global={Global} /></div>
				<div className="col2"><Loader Global={Global} /></div>
			</div>
		 );
	}
	else {
		var replyButton = !commentPending ? <button onClick={postComment}>Reply</button> : <button disabled>Reply (jk you cant, gotta wait)</button>
		return (
			<div className="mainView">
				<div className="col1">
					<Navigation Global={Global} />
				</div>
				<div className="col2">
					<div className="ViewQuack">
						<MainQuack Global={Global} duck={duck} quack={quack} />
						<div className="comments">
								<h4>Comments</h4>
								<input type="text" placeholder="Leave a comment" onChange={(event) => setCommentText(event.target.value)} />
								{ replyButton }
								{children.quacks.map((childQuack) => {
									var childDuck = children.ducks[childQuack.duckID];
									return (
										<FeedQuack Global={Global} duck={childDuck} quack={childQuack} key={childQuack.id} />
									);
								})}
							</div>
					</div>
				</div>
			</div>
		 );
	}
	
}
 
export default ViewQuack;