import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './styles/Signup.css';

/**
 * 
 * @param {Globals} Global 
 * @returns 
 */
const Signup = ({Global}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState();

	const handleSignup = (event) => {
		event.preventDefault();
		doSignup();
	};

	const doSignup = async () => {
		setLoading(true);
		var req = await fetch("https://api.gduck.io:6180/v1/auth/signup", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"client": "GDReact/0.1.15"
			},
			body: JSON.stringify({username, password, email, name})
		});
		var res = await req.json();
		setLoading(false);
        alert(JSON.stringify(res));
		if(res["code"]===0) {
			Global.saveToken(res["data"]["token"]);
			navigate("/");
		}
	};

	return ( 
		<div className="Signup">
			<form onSubmit={handleSignup}>
				<h1>New to GrayDuck?</h1>
				<input type="text" placeholder="Username" onChange={(event) => setUsername(event.target.value)} />
				<input type="password" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
                <input type="text" placeholder="Display Name" onChange={(event) => setName(event.target.value)} />
                <input type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)} />
                <hr />
				<button>Sign Up</button>
			</form>
		</div>
	);
}
 
export default Signup;