import './App.css';
import Login from './pages/Login';
import Home from './pages/Home';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import ViewQuack from './pages/ViewQuack';
import ViewDuck from './pages/ViewDuck';
import Signup from './pages/Signup';

class Globals {
	constructor() {
		// Token
		let storedToken = window.localStorage.getItem("zx-gdk-token") ? window.localStorage.getItem("zx-gdk-token") : null;
		// Get token from url parameter
		const urlParams = new URLSearchParams(window.location.search);
		let providedToken = urlParams.get('token');
		if(providedToken) {
			this.token = providedToken;
			window.localStorage.setItem("zx-gdk-token", providedToken);
		}
		else {
			this.token = storedToken;
		}
		this.duck = null;
		console.log("Global initialized")
	}

	saveToken(token) {
		this.token = token;
		window.localStorage.setItem("zx-gdk-token", token);
	}
	clearToken(token) {
		this.token = null;
		window.localStorage.removeItem("zx-gdk-token");
	}
}

const Global = new Globals();

function App() {

	const router = createBrowserRouter([
		{
			path: "/",
			element: <Home Global={Global} type="all" />,
		},
		{
			path: "following",
			element: <Home Global={Global} type="following" />,
		},
		{
			path: "login",
			element: <Login Global={Global} />,
		},
		{
			path: "signup",
			element: <Signup Global={Global} />,
		},
		{
			path: "quack/:qid",
			element: <ViewQuack Global={Global} />,
		}
		,
		{
			path: "duck/:username",
			element: <ViewDuck Global={Global} />,
		}
	])

	return (
		<RouterProvider router={router} />
	);
}

export default App;
